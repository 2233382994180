import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin({
  name: '@kindandconscious/router-smooth-scroll',
  parallel: true,
  async setup (nuxtApp) {
    nuxtApp.$router.options.scrollBehavior = (to, _from, savedPosition) => {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth'
        }
      }
  
      if (savedPosition) {
        return savedPosition
      }
      
      return {
        top: 0,
        behavior: 'smooth'
      }
    }
  }
})