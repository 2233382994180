import revive_payload_client_yeOJ1JJb4Z from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.7/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PKxVcXUBaz from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.7/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_T1w7519ro9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.7/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_k0whuEsEHt from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@1.6.6_@nuxt+devtools@1.0.5_@vue+compiler-core@3.3.11_nuxt@3.8.2_postcss@8.4._2lixtxkmvhk6f6i47kksxgsfbi/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_phrv81KFOM from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.7/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_IegsCcPIy3 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_vue@3.3.11/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_DTz35C7iHR from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.7/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_7mNpmSDdrY from "/vercel/path0/node_modules/.pnpm/@nuxtjs+web-vitals@0.2.6/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import chunk_reload_client_3mlM3bIYQU from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.7/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_KfKrLqMAN6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@5.0.7/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import router_scrollBehaviour_c9NVjBhrnl from "/vercel/path0/plugins/router.scrollBehaviour.ts";
import vercel_analytics_c3AA3Stl5x from "/vercel/path0/plugins/vercel.analytics.ts";
export default [
  revive_payload_client_yeOJ1JJb4Z,
  unhead_PKxVcXUBaz,
  router_T1w7519ro9,
  _0_siteConfig_k0whuEsEHt,
  payload_client_phrv81KFOM,
  plugin_vue3_IegsCcPIy3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DTz35C7iHR,
  plugin_client_7mNpmSDdrY,
  chunk_reload_client_3mlM3bIYQU,
  check_outdated_build_client_KfKrLqMAN6,
  router_scrollBehaviour_c9NVjBhrnl,
  vercel_analytics_c3AA3Stl5x
]