import { default as index8bNsuUinLTMeta } from "/vercel/path0/pages/brands/index.vue?macro=true";
import { default as indextHkCpcsk7qMeta } from "/vercel/path0/pages/cart/index.vue?macro=true";
import { default as indexkTfPRNbYU3Meta } from "/vercel/path0/pages/collections/[collectionHandle]/index.vue?macro=true";
import { default as _91productHandle_93WPFDzIwgR4Meta } from "/vercel/path0/pages/collections/[collectionHandle]/products/[productHandle].vue?macro=true";
import { default as index57WSLpJsapMeta } from "/vercel/path0/pages/collections/[collectionHandle]/products/index.vue?macro=true";
import { default as indexKWzcwof1WgMeta } from "/vercel/path0/pages/collections/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexBjy4fyVSpFMeta } from "/vercel/path0/pages/new-in/index.vue?macro=true";
import { default as indexxTlJ21KDAmMeta } from "/vercel/path0/pages/order/[orderId]/index.vue?macro=true";
import { default as indexxgYzRekJmpMeta } from "/vercel/path0/pages/order/thankyou/index.vue?macro=true";
import { default as indexa7SLYrUpNFMeta } from "/vercel/path0/pages/privacy/index.vue?macro=true";
import { default as _91productHandle_93nWbR4jRC3JMeta } from "/vercel/path0/pages/products/[productHandle].vue?macro=true";
import { default as indexDJjD9NcZ4YMeta } from "/vercel/path0/pages/products/index.vue?macro=true";
import { default as indexd6bGMgkOeOMeta } from "/vercel/path0/pages/returns/index.vue?macro=true";
import { default as indexioogOXGhU4Meta } from "/vercel/path0/pages/story/index.vue?macro=true";
import { default as indexwaxS9hHRn6Meta } from "/vercel/path0/pages/sustainability/index.vue?macro=true";
import { default as index9OiDgfx7OcMeta } from "/vercel/path0/pages/terms/index.vue?macro=true";
export default [
  {
    name: index8bNsuUinLTMeta?.name ?? "brands",
    path: index8bNsuUinLTMeta?.path ?? "/brands",
    meta: index8bNsuUinLTMeta || {},
    alias: index8bNsuUinLTMeta?.alias || [],
    redirect: index8bNsuUinLTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: indextHkCpcsk7qMeta?.name ?? "cart",
    path: indextHkCpcsk7qMeta?.path ?? "/cart",
    meta: indextHkCpcsk7qMeta || {},
    alias: indextHkCpcsk7qMeta?.alias || [],
    redirect: indextHkCpcsk7qMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexkTfPRNbYU3Meta?.name ?? "collections-collectionHandle",
    path: indexkTfPRNbYU3Meta?.path ?? "/collections/:collectionHandle()",
    meta: indexkTfPRNbYU3Meta || {},
    alias: indexkTfPRNbYU3Meta?.alias || [],
    redirect: indexkTfPRNbYU3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/collections/[collectionHandle]/index.vue").then(m => m.default || m)
  },
  {
    name: _91productHandle_93WPFDzIwgR4Meta?.name ?? "collections-collectionHandle-products-productHandle",
    path: _91productHandle_93WPFDzIwgR4Meta?.path ?? "/collections/:collectionHandle()/products/:productHandle()",
    meta: _91productHandle_93WPFDzIwgR4Meta || {},
    alias: _91productHandle_93WPFDzIwgR4Meta?.alias || [],
    redirect: _91productHandle_93WPFDzIwgR4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/collections/[collectionHandle]/products/[productHandle].vue").then(m => m.default || m)
  },
  {
    name: index57WSLpJsapMeta?.name ?? "collections-collectionHandle-products",
    path: index57WSLpJsapMeta?.path ?? "/collections/:collectionHandle()/products",
    meta: index57WSLpJsapMeta || {},
    alias: index57WSLpJsapMeta?.alias || [],
    redirect: index57WSLpJsapMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/collections/[collectionHandle]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexKWzcwof1WgMeta?.name ?? "collections",
    path: indexKWzcwof1WgMeta?.path ?? "/collections",
    meta: indexKWzcwof1WgMeta || {},
    alias: indexKWzcwof1WgMeta?.alias || [],
    redirect: indexKWzcwof1WgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexBjy4fyVSpFMeta?.name ?? "new-in",
    path: indexBjy4fyVSpFMeta?.path ?? "/new-in",
    meta: indexBjy4fyVSpFMeta || {},
    alias: indexBjy4fyVSpFMeta?.alias || [],
    redirect: indexBjy4fyVSpFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/new-in/index.vue").then(m => m.default || m)
  },
  {
    name: indexxTlJ21KDAmMeta?.name ?? "order-orderId",
    path: indexxTlJ21KDAmMeta?.path ?? "/order/:orderId()",
    meta: indexxTlJ21KDAmMeta || {},
    alias: indexxTlJ21KDAmMeta?.alias || [],
    redirect: indexxTlJ21KDAmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/order/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxgYzRekJmpMeta?.name ?? "order-thankyou",
    path: indexxgYzRekJmpMeta?.path ?? "/order/thankyou",
    meta: indexxgYzRekJmpMeta || {},
    alias: indexxgYzRekJmpMeta?.alias || [],
    redirect: indexxgYzRekJmpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/order/thankyou/index.vue").then(m => m.default || m)
  },
  {
    name: indexa7SLYrUpNFMeta?.name ?? "privacy",
    path: indexa7SLYrUpNFMeta?.path ?? "/privacy",
    meta: indexa7SLYrUpNFMeta || {},
    alias: indexa7SLYrUpNFMeta?.alias || [],
    redirect: indexa7SLYrUpNFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: _91productHandle_93nWbR4jRC3JMeta?.name ?? "products-productHandle",
    path: _91productHandle_93nWbR4jRC3JMeta?.path ?? "/products/:productHandle()",
    meta: _91productHandle_93nWbR4jRC3JMeta || {},
    alias: _91productHandle_93nWbR4jRC3JMeta?.alias || [],
    redirect: _91productHandle_93nWbR4jRC3JMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/products/[productHandle].vue").then(m => m.default || m)
  },
  {
    name: indexDJjD9NcZ4YMeta?.name ?? "products",
    path: indexDJjD9NcZ4YMeta?.path ?? "/products",
    meta: indexDJjD9NcZ4YMeta || {},
    alias: indexDJjD9NcZ4YMeta?.alias || [],
    redirect: indexDJjD9NcZ4YMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexd6bGMgkOeOMeta?.name ?? "returns",
    path: indexd6bGMgkOeOMeta?.path ?? "/returns",
    meta: indexd6bGMgkOeOMeta || {},
    alias: indexd6bGMgkOeOMeta?.alias || [],
    redirect: indexd6bGMgkOeOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/returns/index.vue").then(m => m.default || m)
  },
  {
    name: indexioogOXGhU4Meta?.name ?? "story",
    path: indexioogOXGhU4Meta?.path ?? "/story",
    meta: indexioogOXGhU4Meta || {},
    alias: indexioogOXGhU4Meta?.alias || [],
    redirect: indexioogOXGhU4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/story/index.vue").then(m => m.default || m)
  },
  {
    name: indexwaxS9hHRn6Meta?.name ?? "sustainability",
    path: indexwaxS9hHRn6Meta?.path ?? "/sustainability",
    meta: indexwaxS9hHRn6Meta || {},
    alias: indexwaxS9hHRn6Meta?.alias || [],
    redirect: indexwaxS9hHRn6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: index9OiDgfx7OcMeta?.name ?? "terms",
    path: index9OiDgfx7OcMeta?.path ?? "/terms",
    meta: index9OiDgfx7OcMeta || {},
    alias: index9OiDgfx7OcMeta?.alias || [],
    redirect: index9OiDgfx7OcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms/index.vue").then(m => m.default || m)
  }
]